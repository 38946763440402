import {
  documentaryManagerFiles,
  documentaryManagerDetailFile,
} from '@/shared/constants/api.routes'
import http from '@/shared/http'

class DocumentaryManagerService {
  uid

  setUid(uid) {
    if (!uid || uid.length !== 7) {
      return
    }

    this.uid = uid
  }

  async getDocumentaryManagerFiles() {
    const params = {
      uid: this.uid,
    }
    const response = await http.get(documentaryManagerFiles, { params })
    return response.data
  }

  async getDocumentaryAssetFile(file) {
    const params = {
      uid: this.uid,
      money_asset_key: file,
    }

    const response = await http.get(documentaryManagerDetailFile.asset, { params })
    return response.data
  }

  async getDocumentaryLoanFile(file) {
    const params = {
      uid: this.uid,
      money_loan_key: file,
    }
    const response = await http.get(documentaryManagerDetailFile.loan, { params })
    return response.data
  }

  async getDocumentaryAnnualTaxInformationFile(file) {
    const params = {
      uid: this.uid,
      annual_key_info: file,
    }

    const response = await http.get(documentaryManagerDetailFile.annualTaxInformation, { params })
    return response.data
  }

  async getDocumentaryLoanTaxInformationFile(file) {
    const params = {
      uid: this.uid,
      money_loan_key_info: file,
    }

    const response = await http.get(documentaryManagerDetailFile.loanTaxInformation, { params })
    return response.data
  }

  async getDocumentaryloansCancellationCertificates(file) {
    const params = {
      uid: this.uid,
      certificate_key: file,
    }

    const response = await http.get(documentaryManagerDetailFile.loansCancellationCertificates, {
      params,
    })
    return response.data
  }
}

export default new DocumentaryManagerService()
