<template>
  <div v-if="documentaryManagerDocuments" class="documentary-manager animated fadeIn">
    <app-section class="bg-white">
      <h1 class="title text-46 leading-normal font-semibold my-32 text-grey-900">
        {{ $t('DOCUMENTARY_MANAGER.TITLE') }}
      </h1>
      <h2 class="subtitle text-30 text-grey-900 mb-32">
        {{ $t('DOCUMENTARY_MANAGER.SUBTITLE') }}
      </h2>
      <p class="text text-20 text-grey-900 leading-relaxed text-justify mb-98">
        {{ $t(textKey) }}
      </p>
      <links-wrapper />
      <long-card-wrapper
        v-if="documentaryManagerDocuments"
        :assets="documentaryManagerDocuments.assets"
        :loans="documentaryManagerDocuments.loans"
        :annual-taxs-information="documentaryManagerDocuments.annualTaxsInformation"
        :loans-taxs-information="documentaryManagerDocuments.loansTaxsInformation"
        :loans-cancellation-certificates="documentaryManagerDocuments.loansCancellationCertificates"
        @download="download"
      />
    </app-section>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
import AppSection from '@/components/AppSection'
import LongCardWrapper from './components/LongCardWrapper.vue'
import documentaryManagerService from '@/shared/services/documentaryManagerService'
import { DocumentaryManagerAssembler } from '@/views/documentaryManager/helpers/documentaryAssembler'
import { saveFileAsPdf } from '@/shared/utils/fileDownload'
import LinksWrapper from './components/LinksWrapper'

export default {
  name: 'DocumentaryManager',
  components: {
    AppSection,
    LongCardWrapper,
    LinksWrapper,
  },
  data: () => ({
    documentaryManagerDocuments: undefined,
    documentaryManagerAssembler: new DocumentaryManagerAssembler(),
  }),
  computed: {
    ...mapState('auth', ['uid']),
    ...mapGetters('auth', ['isDc']),

    textKey() {
      return this.isDc ? 'DOCUMENTARY_MANAGER.DC_TEXT' : 'DOCUMENTARY_MANAGER.SENIOR_TEXT'
    },
  },
  mounted() {
    documentaryManagerService.setUid(this.uid)
    this.getFiles()
  },
  methods: {
    ...mapMutations('portal', ['setRequestInProgressStatus']),
    async getFiles() {
      try {
        this.setRequestInProgressStatus(true)
        const documentaryManagerData = await documentaryManagerService.getDocumentaryManagerFiles()
        this.documentaryManagerDocuments = this.documentaryManagerAssembler.assemble(
          documentaryManagerData
        )
        this.setRequestInProgressStatus(false)
      } catch (error) {
        this.setRequestInProgressStatus(false)
        throw new Error(error)
      }
    },
    async download(data) {
      if (!data) {
        return
      }
      try {
        this.setRequestInProgressStatus(true)
        const fileName = data.document.fileName
        const fileNameWithoutExtension = fileName.split('.')
        const downloadMap = this.getDownloadMap()
        const pdfFile = await downloadMap[data.type](fileNameWithoutExtension[0])
        this.setRequestInProgressStatus(false)
        saveFileAsPdf(pdfFile, data.document.fileName)
      } catch (error) {
        this.setRequestInProgressStatus(false)
        throw new Error(error)
      }
    },
    getDownloadMap() {
      return {
        assets: documentaryManagerService.getDocumentaryAssetFile.bind(documentaryManagerService),
        loans: documentaryManagerService.getDocumentaryLoanFile.bind(documentaryManagerService),
        annualTaxsInformation: documentaryManagerService.getDocumentaryAnnualTaxInformationFile.bind(
          documentaryManagerService
        ),
        loansTaxsInformation: documentaryManagerService.getDocumentaryLoanTaxInformationFile.bind(
          documentaryManagerService
        ),
        loansCancellationCertificates: documentaryManagerService.getDocumentaryloansCancellationCertificates.bind(
          documentaryManagerService
        ),
      }
    },
  },
}
</script>
