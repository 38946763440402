export class DocumentaryManagerAssembler {
  assemble(data) {
    if (!data) {
      return
    }

    let assembledFiles = {
      assets: this.assembleFiles(data.recibos_haberes),
      loans: this.assembleFiles(data.recibos_prestamos),
      annualTaxsInformation: this.assembleFiles(data.informacion_fiscal_anual),
      loansTaxsInformation: this.assembleFiles(data.informacion_fiscal_prestamos),
      information: data.retorno,
    }

    if (data.certificado_cancelacion_prestamos) {
      assembledFiles = {
        ...assembledFiles,
        loansCancellationCertificates: this.assembleFiles(data.certificado_cancelacion_prestamos),
      }
    }

    return assembledFiles
  }

  assembleFiles(files) {
    return this.orderFilesAsMostRecentsFirst(files.map((file) => this.assembleFile(file)))
  }

  assembleFile(file) {
    let assembledFile = {
      fileName: file.id,
      date: file.periodo,
      assembledDate: this.assembleDate(file.periodo),
    }

    const isLoanCancellationCertificate = file.idPrestamo && file.modalidad
    if (isLoanCancellationCertificate) {
      assembledFile = {
        ...assembledFile,
        loanId: file.idPrestamo,
        modality: file.modalidad,
      }
    }

    return assembledFile
  }

  assembleDate(date) {
    return {
      month: date.slice(0, 2),
      year: date.slice(2, 6),
    }
  }

  orderFilesAsMostRecentsFirst(files) {
    if (files.length < 2) {
      return files
    }

    return files.sort((a, b) => {
      a = a.assembledDate
      b = b.assembledDate

      if (a.year === b.year) {
        return a.month > b.month ? -1 : 1
      }

      return a.year > b.year ? -1 : 1
    })
  }
}
