<template>
  <div class="flex long-card mt-60 mb-16">
    <div class="flex-auto mt-24 ml-32">
      <h1 class="title text-19 font-semibold text-blue-450">{{ title }}</h1>
      <template v-if="subtitle">
        <p class="subtitle text-16 mt-6 font-semibold text-grey-500">
          {{ subtitle }}
        </p>
      </template>
      <template v-else>
        <p class="date text-16 mt-6 font-semibold text-grey-500">{{ date }}</p>
      </template>
    </div>

    <div class="flex-auto text-right mt-44 mr-24">
      <span class="mr-8"></span>
      <a
        class="link font-semibold text-15 text-blue-500 text-right cursor-pointer"
        target="_blank"
        @click="$emit('download')"
      >
        {{ $t('DOCUMENTARY.DOWNLOAD') }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LongCard',
  props: {
    title: {
      required: true,
      type: String,
    },
    subtitle: {
      type: String,
    },
    year: {
      type: String,
    },
    month: {
      type: String,
    },
  },
  computed: {
    date() {
      if (!this.month || !this.year) {
        return
      }
      const translateKey = `SHARED.MONTHS.${this.month}`

      return `${this.$t(translateKey)}, ${this.year}`
    },
  },
}
</script>

<style lang="scss" scoped>
.long-card {
  width: 100%;
  height: 111px;
  box-shadow: 0 1px 4px 1px theme('colors.grey.250');
}
</style>
