<template>
  <div class="links-wrapper flex w-full">
    <a
      class="link w-1/4 text-center h-80 px-4 py-28 m-2 hover:bg-blue-400 hover:text-white text-18 text-blue-450 font-semibold"
      href="#assets"
      @click="jumpTo('#assets')"
    >
      {{ $t('DOCUMENTARY_MANAGER.LIST_LITERALS.ASSETS') }}
    </a>
    <a
      class="link w-1/4 text-center h-80 px-4 py-28 m-2 hover:bg-blue-400 hover:text-white text-18 text-blue-450 font-semibold"
      href="#loans"
      @click="jumpTo('#loans')"
    >
      {{ $t('DOCUMENTARY_MANAGER.LIST_LITERALS.LOANS') }}
    </a>
    <a
      class="link w-1/4 text-center h-80 px-4 py-28 m-2 hover:bg-blue-400 hover:text-white text-18 text-blue-450 font-semibold"
      href="#annualTaxs"
      @click="jumpTo('#annualTaxs')"
    >
      {{ $t('DOCUMENTARY_MANAGER.LIST_LITERALS.ANNUAL_TAXS_INFORMATION') }}
    </a>
    <a
      class="link w-1/4 text-center h-80 px-4 py-28 m-2 hover:bg-blue-400 hover:text-white text-18 text-blue-450 font-semibold"
      href="#loansTaxs"
      @click="jumpTo('#loansTaxs')"
    >
      {{ $t('DOCUMENTARY_MANAGER.LIST_LITERALS.LOANS_TAXS_INFORMATION') }}
    </a>
    <a
      v-if="isDc"
      class="link w-1/4 text-center h-80 px-4 py-28 m-2 hover:bg-blue-400 hover:text-white text-18 text-blue-450 font-semibold"
      href="#loansCancellationCertificates"
      @click="jumpTo('#loansCancellationCertificates')"
    >
      {{ $t('DOCUMENTARY_MANAGER.LIST_LITERALS.LOANS_CANCELLATION_CERTIFICATES') }}
    </a>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'LinksWrapper',
  computed: {
    ...mapGetters('auth', ['isDc']),
  },
  methods: {
    jumpTo(hash) {
      location.hash = hash
    },
  },
}
</script>
