<template>
  <div class="long-card-wrapper mb-98">
    <div v-if="assets.length" id="assets">
      <h2 class="documentary-section text-30 text-grey-900 mb-32">
        {{ $t('DOCUMENTARY_MANAGER.LIST_LITERALS.ASSETS') }}
      </h2>
      <div class="long-card-wrapper-container py-10 pr-10 pl-3">
        <long-card
          v-for="(asset, index) in assets"
          :key="index"
          class="asset-long-card"
          :title="assetFileNameBuilder(asset)"
          :month="asset.assembledDate.month"
          :year="asset.assembledDate.year"
          @download="emitDownload(asset, 'assets')"
        />
      </div>
    </div>
    <div v-if="loans.length" id="loans" class="long-card-wrapper-section mt-64">
      <h2 class="documentary-section text-30 text-grey-900 mb-32">
        {{ $t('DOCUMENTARY_MANAGER.LIST_LITERALS.LOANS') }}
      </h2>
      <div class="long-card-wrapper-container py-10 pr-10 pl-3">
        <long-card
          v-for="(loan, index) in loans"
          :key="index"
          class="loan-long-card"
          :title="loanFileNameBuilder(loan)"
          :month="loan.assembledDate.month"
          :year="loan.assembledDate.year"
          @download="emitDownload(loan, 'loans')"
        />
      </div>
    </div>
    <div
      v-if="annualTaxsInformation.length"
      id="annualTaxs"
      class="long-card-wrapper-section mt-64"
    >
      <h2 class="documentary-section text-30 text-grey-900 mb-32">
        {{ $t('DOCUMENTARY_MANAGER.LIST_LITERALS.ANNUAL_TAXS_INFORMATION') }}
      </h2>
      <div class="long-card-wrapper-container py-10 pr-10 pl-3">
        <long-card
          v-for="(annualTaxInformation, index) in annualTaxsInformation"
          :key="index"
          class="annual-tax-information-long-card"
          :title="annualTaxInformationFileNameBuilder(annualTaxInformation)"
          :month="annualTaxInformation.assembledDate.month"
          :year="annualTaxInformation.assembledDate.year"
          @download="emitDownload(annualTaxInformation, 'annualTaxsInformation')"
        />
      </div>
    </div>
    <div v-if="loansTaxsInformation.length" id="loansTaxs" class="long-card-wrapper-section mt-64">
      <h2 class="documentary-section text-30 text-grey-900 mb-32">
        {{ $t('DOCUMENTARY_MANAGER.LIST_LITERALS.LOANS_TAXS_INFORMATION') }}
      </h2>
      <div class="long-card-wrapper-container py-10 pr-10 pl-3">
        <long-card
          v-for="(loansTaxInformation, index) in loansTaxsInformation"
          :key="index"
          class="loans-tax-information-long-card"
          :title="loanTaxInformationFileNameBuilder(loansTaxInformation)"
          :month="loansTaxInformation.assembledDate.month"
          :year="loansTaxInformation.assembledDate.year"
          @download="emitDownload(loansTaxInformation, 'loansTaxsInformation')"
        />
      </div>
    </div>
    <div
      v-if="loansCancellationCertificates && loansCancellationCertificates.length"
      id="loansCancellationCertificates"
      class="long-card-wrapper-section mt-64"
    >
      <h2 class="documentary-section text-30 text-grey-900 mb-32">
        {{ $t('DOCUMENTARY_MANAGER.LIST_LITERALS.LOANS_CANCELLATION_CERTIFICATES') }}
      </h2>
      <div class="long-card-wrapper-container py-10 pr-10 pl-3">
        <long-card
          v-for="(loanCancellationCertificate, index) in loansCancellationCertificates"
          :key="index"
          class="loan-cancellation-certificate-long-card"
          :title="loanCancellationCertificatesTitle"
          :subtitle="`${loanCancellationCertificate.modality} - ${loanCancellationCertificate.loanId}`"
          @download="emitDownload(loanCancellationCertificate, 'loansCancellationCertificates')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import LongCard from '@/components/LongCard.vue'

export default {
  name: 'LongCardWrapper',
  components: {
    LongCard,
  },
  props: {
    assets: {
      type: Array,
    },
    loans: {
      type: Array,
    },
    annualTaxsInformation: {
      type: Array,
    },
    loansTaxsInformation: {
      type: Array,
    },
    loansCancellationCertificates: {
      type: Array,
    },
  },
  computed: {
    loanCancellationCertificatesTitle() {
      return `${this.$t('DOCUMENTARY_MANAGER.DOCUMENTS_PREFIXES.LOANS_CANCELLATION_CERTIFICATES')}`
    },
  },
  methods: {
    emitDownload(document, type) {
      this.$emit('download', { document: document, type: type })
    },
    assetFileNameBuilder(file) {
      return `${this.$t('DOCUMENTARY_MANAGER.DOCUMENTS_PREFIXES.ASSET')} ${this.dateBuilder(
        file.assembledDate
      )}`
    },
    loanFileNameBuilder(file) {
      return `${this.$t('DOCUMENTARY_MANAGER.DOCUMENTS_PREFIXES.LOAN')} ${this.dateBuilder(
        file.assembledDate
      )}`
    },
    annualTaxInformationFileNameBuilder(file) {
      return `${this.$t(
        'DOCUMENTARY_MANAGER.DOCUMENTS_PREFIXES.ANNUAL_TAX_INFORMATION'
      )} ${this.dateBuilder(file.assembledDate)}`
    },
    loanTaxInformationFileNameBuilder(file) {
      return `${this.$t(
        'DOCUMENTARY_MANAGER.DOCUMENTS_PREFIXES.LOANS_TAX_INFORMATION'
      )} ${this.dateBuilder(file.assembledDate)}`
    },
    dateBuilder(date) {
      if (!date) {
        return
      }
      const translateKey = `SHARED.MONTHS.${date.month}`

      return `${this.$t(translateKey)}, ${date.year}`
    },
  },
}
</script>
